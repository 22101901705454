import { useEffect, useState } from "react";
import "./styles.css";

import icon from "../../assets/iconwhats.png";

const CtaWpp = (props) => {
  const [text, setText] = useState("(11) 96549-0064");

  useEffect(() => {
    if(props.text !== undefined) setText(props.text);
  }, [])

  return (
    <button id="ctaWpp">
      <a
        href="http://wa.me/+5511965490064?text=Preciso%20de%20um%20orçamento"
        target="_blank"
        rel="noreferrer"
      >
        <figure>
          <img src={icon} alt="Ícone do Whatsapp" />
        </figure>
        <p>{text}</p>
      </a>
    </button>
  );
};

export default CtaWpp;
