import "./styles.css";

import CtaWpp from "../../components/CTAWpp";
import CtaTel from "../../components/CTATel";

import item1 from "../../assets/item1.png";
import item2 from "../../assets/item2.png";
import item3 from "../../assets/item3.png";
import about3_1 from "../../assets/about3_1.png";
import about3_2 from "../../assets/about3_2.png";
import about3_3 from "../../assets/about3_3.png";
import about3_4 from "../../assets/about3_4.png";

const About = () => {
  return (
    <section id="about">
      <article className="about1">
        <div className="container">
          <h2 className="title">principais serviços</h2>

          <div className="gallery">
            <div className="item">
              <figure>
                <img src={item1} alt="Homem dedetizando" />
              </figure>

              <h2 className="title">DESINSETIZAÇÃO</h2>

              <p className="text">
                Somos especialistas em combater pragas urbanas como baratas,
                formigas, pulgas, traças, aranhas, escorpiões, moscas e
                mosquitos. Utilizamos produtos eficientes, registrados e
                autorizados pelo Ministério da Saúde, desenvolvidos por
                renomados laboratórios. Nossos serviços são rápidos, limpos e
                garantidos.
              </p>

              <CtaWpp text={"PEDIR ORÇAMENTO"} />
            </div>

            <div className="item">
              <figure>
                <img src={item2} alt="Homem preparando as ferramentas" />
              </figure>

              <h2 className="title">DESRATIZAÇÃO</h2>

              <p className="text">
                Desratização é o serviço específico para acabar com uma
                infestação de ratos, e para isso temos uma equipe de
                profissionais altamente capacitados, que utilizam técnicas
                especializadas para eliminar tais roedores, ratazanas e
                camundongos, seja em ambientes residenciais, industriais ou
                comerciais.
              </p>

              <CtaWpp text={"PEDIR ORÇAMENTO"} />
            </div>

            <div className="item">
              <figure>
                <img src={item3} alt="Homem dedetizando" />
              </figure>

              <h2 className="title">DESCUPINIZAÇÃO</h2>

              <p className="text">
                O cocô de cupim, encontrado em superfícies de madeira, é um
                indicador evidente de uma infestação em sua residência.
                <span>
                  Com a experiência, processos e produtos adequados, nossa
                  equipe oferece soluções eficazes para eliminar cupins e
                  preservar a integridade do seu patrimônio
                </span>
              </p>

              <CtaWpp text={"PEDIR ORÇAMENTO"} />
            </div>
          </div>
        </div>
      </article>

      <article className="about2">
        <div className="container">
          <h2 className="title">
            <span>PORQUE CONTRATAR A DEDETIZADORA MNC</span>
            PARA FAZER A DEDETIZAÇÃO DO SEU ESTABELECIMENTO?
          </h2>

          <p className="text">
            A dedetização elimina pragas, reduzindo o risco de doenças e
            alergias, protege as estruturas contra danos causados por insetos e
            garante a conformidade com os padrões de higiene e segurança.
            <span>
              Contamos com profissionais treinados, os melhores equipamentos e
              produtos sem cheiro, ante-alérgico. Utilizamos produtos químicos
              autorizados pelo ministério da saúde, através do seu órgão
              fiscalizador Anvisa. Tudo para entregar um serviço eficiente e
              essencial para garantir a saúde, prevenir danos estruturais e
              preservar a reputação dos estabelecimentos dos nossos clientes.
            </span>
          </p>

          <div className="cta">
            <p className="text">SOLICITE AGORA MESMO UM ORÇAMENTO::</p>

            <div className="cta_components">
              <CtaWpp />
              <CtaTel />
            </div>
          </div>
        </div>
      </article>

      <article className="about3">
        <div className="container">
          <h2 className="title">nosso atendimento</h2>

          <h3 className="subtitle">Realizamos serviços em vários setores</h3>

          <div className="gallery">
            <div className="item">
              <figure>
                <img src={about3_1} alt="Residências" />
              </figure>
              <p className="text">residências</p>
            </div>

            <div className="item">
              <figure>
                <img src={about3_2} alt="comércios" />
              </figure>
              <p className="text">comércios</p>
            </div>

            <div className="item">
              <figure>
                <img src={about3_3} alt="condomínios" />
              </figure>
              <p className="text">condomínios</p>
            </div>

            <div className="item">
              <figure>
                <img src={about3_4} alt="indústrias" />
              </figure>
              <p className="text">indústrias</p>
            </div>
          </div>

          <div className="cta">
            <p className="text">SOLICITE AGORA MESMO UM ORÇAMENTO::</p>

            <div className="cta_components">
              <CtaWpp />
              <CtaTel />
            </div>
          </div>
        </div>
      </article>

      <article className="about4">
        <div className="container">
          <b>ATENDEMOS EM: </b>São Paulo, Grande São Paulo e Alto Tietê
        </div>
      </article>
    </section>
  );
};

export default About;
