import "./styles.css";

import CtaWpp from "../../components/CTAWpp";
import CtaTel from "../../components/CTATel";

import logo from "../../assets/logo_topo.png";
import header3 from "../../assets/header3.png";

const Header = () => {
  return (
    <header id="header">
      <section className="header1">
        <article className="container">
          <figure className="top">
            <img src={logo} alt="Logo" />
          </figure>

          <div className="cta">
            <p className="text">SOLICITE UM ORÇAMENTO:</p>

            <div className="cta_components">
              <CtaWpp />
              <CtaTel />
            </div>
          </div>
        </article>
      </section>

      <section className="header2">
        <article className="container">
          <h2 className="title">
            ATENDEMOS EM: <span>São Paulo, Grande São Paulo e Alto Tietê.</span>{" "}
          </h2>
        </article>
      </section>

      <section className="header3">
        <article className="container">
          <h2 className="title">
            Trabalhamos no controle de pragas urbanas,{" "}
            <span>no nível de traças a escorpião</span>
          </h2>

          <h3 className="subtitle">
            ELIMINAMOS Baratas, ratos, moscas, cupim, aranhas, formigas, insetos
            rasteiros e voadores em geral.
          </h3>

          <figure>
            <img src={header3} alt="Insetos dedetizados" />
          </figure>

          <div className="cta">
            <p className="text">SOLICITE AGORA MESMO UM ORÇAMENTO:</p>

            <div className="cta_components">
              <CtaWpp />
              <CtaTel />
            </div>
          </div>
        </article>
      </section>
    </header>
  );
};

export default Header;
